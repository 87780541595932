<template>
  <span class="inline-block float-right mt-1 mr-2 text-xs text-gray-400">
    {{ $t('general.app_version') }} : {{ appVersion }}
  </span>
</template>
<script setup>
import { ref } from 'vue';
import { useRuntimeConfig } from '#imports';

const appVersion = ref('');

const runtimeConfig = useRuntimeConfig();
if (runtimeConfig.public) {
  appVersion.value = runtimeConfig.public.appVersion;
}
</script>
