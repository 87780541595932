<template>
  <div class="w-full border-t flex-1">
    <div class="p-3 text-center 2xl:text-mr-3">
      <ClearLink
        :element-id="`about-us`"
        :to-link="`/about-us`"
        class="text-blue-500 mr-2 text-sm 2xl:text-base"
        target="_blank"
      >
        {{ $t('about_us') }}
      </ClearLink>
      <span class="mr-2 text-sm 2xl:text-base">|</span>
      <ClearLink
        :element-id="`terms-of-use`"
        :to-link="`/terms-of-use`"
        class="text-blue-500 mr-2 text-sm 2xl:text-base"
        target="_blank"
      >
        {{ $t('terms_of_use') }}
      </ClearLink>
      <span class="mr-2 text-sm 2xl:text-base">|</span>
      <a class="text-blue-500 mr-2 text-sm 2xl:text-base" href="mailto:support@contextlabs.com">
        {{ $t('contact_help_desk') }}
      </a>
      <span class="mr-2 text-sm 2xl:text-base">|</span>
      <ClearLink
        :element-id="`user-guide`"
        :to-link="`/user-guide`"
        class="text-blue-500 mr-2 text-sm 2xl:text-base"
        target="_blank"
      >
        {{ $t('user_guide') }}
      </ClearLink>
      <span class="ml-5 text-sm 2xl:text-base text-rose-900">
        <span class="mt-4">*</span> <small>All dates in UTC timezone</small></span
      >
      <TheAppVersion></TheAppVersion>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
