<template>
  <TheModalBox v-if="isInactivity" v-model="isInactivity" :model-close="false" :model-width="`max-w-md`">
    <div class="p-6 space-y-6 max-h-screen flex-col justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <div class="session-icon w-full flex flex-row items-center justify-center">
          <div
            class="flex flex-row items-center justify-center overflow-hidden rounded-full bottom-5 left-5"
            x-data="scrollProgress"
          >
            <svg class="w-20 h-20">
              <circle
                class="text-blue-600"
                cx="40"
                cy="40"
                fill="transparent"
                r="30"
                stroke="currentColor"
                stroke-width="5"
              />
              <circle
                :stroke-dasharray="circumference"
                :stroke-dashoffset="stroke"
                class="text-gray-300"
                cx="40"
                cy="40"
                fill="transparent"
                r="30"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="5"
              />
            </svg>

            <span class="absolute text-xl text-blue-700">{{ count }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-center items-center text-2xl mt-2 mb-2 font-medium">Inactivity Logout</div>
        <div v-if="count > 0" class="inactivity-session-text text-center">
          Your session is going to expire in seconds. You will be redirected to login page
        </div>
        <div v-else class="inactivity-session-text text-center">
          Your session has Expired. You will be redirected to login page.
        </div>
        <div class="login-redirection-text"></div>
      </div>
    </div>
    <template #modelFooter>
      <div class="flex flex-row-reverse items-center pb-6 pr-6 gap-2 space-x-2">
        <ElementsButtonsTheButton
          :background="`button button--primary`"
          element-id="continue-session"
          @click="continueLogin"
        >
          {{ continueLoginText }}
        </ElementsButtonsTheButton>

        <ElementsButtonsTheButton
          :background="`button button--danger`"
          element-id="logout-session"
          @click="authObj.logout"
        >
          Logout
        </ElementsButtonsTheButton>
      </div>
    </template>
  </TheModalBox>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { useCounter, useIdle } from '@vueuse/core';
import { INACTIVITY_EXPIRE, INACTIVITY_TIME } from '@/utils/constants';
import useAuthStore from '@/stores/useAuthStore';

const isInactivity = ref(false);
const authObj = useAuthStore();
const { idle, reset } = useIdle(INACTIVITY_TIME * 60 * 1000);
const continueLoginText = ref('Continue');
const { dec, count } = useCounter();
const percent = ref(0);
const circumference = ref(0);
circumference.value = Math.PI * INACTIVITY_EXPIRE * 2;
count.value = INACTIVITY_EXPIRE;
const stroke = computed(() => circumference.value - (percent.value / 100) * circumference.value);
let intr = null;
watch(idle, (idleValue) => {
  if (idleValue) {
    isInactivity.value = true;
    if (++idleValue === 0) {
      continueLoginText.value = 'Login';
    }
    intr = setInterval(() => {
      dec && dec();
      percent.value = Math.round(((INACTIVITY_EXPIRE - count.value) / INACTIVITY_EXPIRE) * 100);
      if (count.value === 0) {
        clearInterval(intr);
        authObj.logout();
      }
    }, 1000);
  }
});

const continueLogin = () => {
  if (count.value === 0) {
    authObj.logout();
  } else {
    reset();
    count.value = INACTIVITY_EXPIRE;
    clearInterval(intr);
    isInactivity.value = false;
  }
};
</script>
