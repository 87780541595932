<template>
  <TheModalBox
    v-if="updatedBasketedAsset.length"
    v-model="isBasketSummary"
    :model-close="true"
    :model-title="modelTitle"
    :model-width="modelWidth"
  >
    <div class="items-left p-5 rounded-t border-b border-gray-100">
      <div class="overflow-y-auto min-[60px] max-[96px]">
        <div class="inline-flex items-center text-sm 2xl:text-base text-gray-600 dark:text-white h-12">
          {{ getBasketDetail.summary }}
        </div>
      </div>
    </div>
    <div class="items-left px-5 py-3 border border-gray-100">
      <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
        <li class="pb-0">
          <div class="flex items-left">
            <div class="flex-1 text-blue-900 text-sm 2xl:text-base min-w-0">
              <span v-html="$t('assets.basket.co2_net_emission')"> </span>
            </div>
            <div class="inline-flex items-center text-sm 2xl:text-base text-gray-600 dark:text-white">
              {{ fixNumber(netEmission, 2) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="updatedBasketedAsset.length"
      class="p-0 space-y-6 max-h-60 overflow-y-auto overscroll-auto scroll-smooth flex-col justify-center items-center"
    >
      <ElementsDataGridTable :columns="assetsCols" :rows="updatedBasketedAsset">
        <template #table-header>
          <h4 class="pl-6 font-medium text-base my-2 text-gray-600">{{ $t('assets.basket.assets') }}</h4>
        </template>
      </ElementsDataGridTable>
    </div>
    <template #modelFooter>
      <div class="flex mt-4 flex-row-reverse items-center pb-6 mr-3 pr-3 gap-2 space-x-2">&nbsp;</div>
    </template>
  </TheModalBox>
</template>
<script setup>
import { onBeforeMount, onUnmounted, ref, watchEffect } from 'vue';
import useAssetStore from '@/stores/useAssetStore';
import { storeToRefs } from 'pinia';
import { basketSummaryDetailColumns, fixNumber, handleFloatNumber, useCloneDeep } from '#imports';
import useAssetCol from '@/composables/useAssetCol';
import useModal from '@/stores/useModal';

defineEmits(['update:modelValue']);
const assetObj = useAssetStore();
const { getBasketDetail } = storeToRefs(assetObj);
const clonedCols = useCloneDeep(basketSummaryDetailColumns);
const assetsCols = ref(clonedCols);
const netEmission = ref(0);
const modalObj = useModal();
const updatedBasketedAsset = ref([]);
const props = defineProps({
  basketId: {
    type: Number,
    required: true
  },
  modelWidth: {
    type: String,
    default: 'max-w-5xl'
  },
  modelValue: {
    type: Boolean,
    default: false
  }
});
const modelTitle = ref('');
const isBasketSummary = ref(props.modelValue);
onBeforeMount(async () => {
  assetObj.basketDetail = {};
  await assetObj.getBasketDetailById(props.basketId);
  if (getBasketDetail.value) {
    modelTitle.value = getBasketDetail.value.name;
    updatedBasketedAsset.value = [];
    if (getBasketDetail.value.details) {
      getBasketDetail.value.details.map((detail) => {
        if (detail.emissions === undefined) {
          detail.emissions = 0;
        }
        if (detail.type === 'P') {
          detail.id = detail.basketable_id;
        }
        detail.basketedQuantity = handleFloatNumber(detail.quantity);
        netEmission.value += parseFloat(detail.emissions);
        updatedBasketedAsset.value.push(useAssetCol(detail, false));
        return detail;
      });
    }
  }
});
onUnmounted(() => {
  modalObj.setIsOpen(false);
});
watchEffect(() => {
  if (!isBasketSummary.value) {
    modalObj.setIsOpen(false);
  }
});
</script>
