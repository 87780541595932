<template>
  <div
    :class="routesData.includes(router.currentRoute.value.fullPath) ? 'hideGridScroll' :''"
    class="w-full h-full overflow-y-auto">
    <template v-if="!isShowModel">
      <slot />
    </template>
    <template v-else>
      <div class="grid px-2 pt-4 mx-auto h-screen"></div>
    </template>
    <Teleport to="body">
      <HocModalBox
        v-if="isShowModel"
        v-model="isShowModel"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
      >
        <div class="relative my-6 w-96">
          <div
            class="border-0 rounded-md 2xl:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <div
              class="flex items-start justify-between p-2 px-3 2xl:p-3 border-b border-solid border-slate-200 rounded-t"
            >
              <h3 class="text-md 2xl:text-2xl font-medium text-gray-600">
                {{ $t('menu_items.roles_permissions.select_realms') }}
              </h3>
            </div>
            <div class="relative px-4 py-5 flex-auto">
              <ElementsClearForm
                v-if="realmListInputs.realms.options()"
                ref="realmInputForm"
                :submit-form="handleSubmit"
                class="flex flex-col"
              >
                <ElementsInputsClearInput
                  v-for="(myEl, i) in realmListInputs"
                  :key="`elm-${i}`"
                  v-model="myEl.value"
                  :class="myEl.divClass"
                  :element-id="myEl.id"
                  :elm-key="`elm-${i}`"
                  :error="myEl.error"
                  :error-message="myEl.errorMessageKey"
                  :image-key="myEl.imageKey"
                  :is-image="myEl.isImage"
                  :label="myEl.labelKey"
                  :option-key="myEl.optionValue"
                  :option-value="myEl.optionValue"
                  :options="myEl.options"
                  :placeholder="myEl.placeHolderKey"
                  :type="myEl.type"
                ></ElementsInputsClearInput>
                <div class="flex gap-2 items-center 2xl:mt-5 mt-4 justify-end">
                  <ElementsButtonsTheButton
                    class="button--danger"
                    element-id="realm-model-cancel"
                    type="button"
                    @click="handleCancelling"
                  >{{ $t('buttons.cancel') }}
                  </ElementsButtonsTheButton>
                  <ElementsButtonsTheButton
                    :class="{
                      'opacity-50 cursor-not-allowed': !(realmListInputs.realms.value > 0)
                    }"
                    class="button--primary"
                    element-id="realm-apply"
                    type="submit"
                  >{{ $t('buttons.apply') }}
                  </ElementsButtonsTheButton>
                </div>
              </ElementsClearForm>
            </div>
          </div>
        </div>
      </HocModalBox>
      <div v-if="isShowModel" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </Teleport>
  </div>
</template>
<script setup>
import { reactive, ref, watchEffect } from 'vue';
import { navigateTo, useNuxtApp, useRouter} from '#imports';
import useCachedRealms from '@/composables/useCachedRealms';
import useGeneralStore from '@/stores/useGeneralStore';
import useAuthStore from '@/stores/useAuthStore';
import { routesData } from '@/utils/constants';
const router = useRouter();
const generalObj = useGeneralStore();
const authObj = useAuthStore();
const isShowModel = ref(false);
if (!authObj.isSuperAdmin) {
  isShowModel.value = authObj.getIsAuthenticated;
  generalObj.setRealmId(authObj.getUser.realm_id);
}
const realmListInputs = reactive({
  realms: {
    type: 'select',
    id: 'realm-list',
    options: () => undefined,
    selectDisplayKey: 'short_name',
    optionValue: 'name',
    labelKey: 'Choose Realm',
    imageKey: 'brand',
    isImage: true,
    divClass: 'mb-0',
    errorMessageKey: 'input.errors.name.required',
    placeHolderKey: '--Choose--',
    error: false,
    value: 0
  }
});
const handleSubmit = () => {
  generalObj.setRealmId(realmListInputs.realms.value);
  useNuxtApp().$event('setRealmId', true);
  if (generalObj.visitTo) {
    navigateTo(generalObj.visitTo);
  }
};
const handleCancelling = () => {
  if (!generalObj.realmId) {
    generalObj.setRealmId(0);
  }
  isShowModel.value = false;
  navigateTo('/dashboard');
};
watchEffect(() => {
  if (generalObj.realmId) {
    isShowModel.value = false;
  }
});
const collectRealms = async () => {
  const realms = await useCachedRealms();
  realmListInputs.realms.options = () => realms || [];
  realmListInputs.realms.value = generalObj.realmId;
  isShowModel.value = true;
};
if (process.client) {
  useNuxtApp().$listen('openRealmModel', () => {
    if (authObj.isSuperAdmin) {
      collectRealms();
    }
  });
}
</script>
